import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let url = useDataValue({
    context: 'payor',
    path: 'contract.url_signed',
    viewPath: props.viewPath,
  })

  return {
    variables: { url },
    pause: !url,
  }
}
