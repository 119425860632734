// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/PaymentOverdue/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_PaymentOverdue($patient_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    where: { due_now: { _gt: 0 } }
    distinct_on: [payment_account_id]
  ) {
    id: payment_account_id
    patient_id
    due_now
    payment_account {
      id
      account_type
      person_payment_account {
        id
        _id
        payer_person {
          id
          _id
          first_name
          greeting
          last_name
        }
      }
      insurance_payment_accounts {
        id
        insurance_company {
          id
          carrier_name
        }
      }
    }
  }
}

`