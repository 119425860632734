import { useEffect, useRef } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import View from './view.js'

let IDS_NOTIFICATION_MESSAGES = {
  invalid_grant:
    'Patient could not be created in Align. Please reconnect the Invisalign® Doctor Site application from Settings and try again. If the problem persists, contact Support.',
  'Automation to create patient in IDS': {
    failed:
      'Patient could not be created in Align. Please ensure the patient profile in Greyfinch has a recorded address with zip code, gender (female or male), date of birth, and that the first and last names are less than 25 characters',
    completed: 'Patient successfully created in Align',
  },
  'Archive IDS patients': {
    failed: 'Unable to archive patient in IDS',
    completed: 'Patient archived successfully in IDS',
  },
}
let ITERO_NOTIFICATION_MESSAGES = {
  invalid_grant:
    'Scan could not be created in iTero. Please reconnect the iTero application from Settings and try again. If the problem persists, contact Support.',
  'Automation to perform a scan request in iTero': {
    failed: 'Scan could not be created in iTero',
    completed: 'Scan successfully created in iTero',
  },
}

// TODO: refactor this into the automation, eg as a notification config
function getMessage({ event_source, event_status, event_name }) {
  try {
    if (event_source.includes('itero')) {
      return ITERO_NOTIFICATION_MESSAGES[event_name][event_status]
    } else if (event_source.includes('ids')) {
      return IDS_NOTIFICATION_MESSAGES[event_name][event_status]
    } else {
      return null
    }
  } catch {
    return null
  }
}

export default function Logic(props) {
  let event_executions = useDataValue({
    context: 'event_executions',
    viewPath: props.viewPath,
  })
  let setRefreshAppsLaunchers = useDataChange({
    context: 'global',
    path: 'refresh_apps_launchers',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()

  let processed_events = useRef(new Set())

  useEffect(() => {
    event_executions
      .filter(item => !processed_events.current.has(item.id))
      .forEach(item => {
        if (
          item.status === 'completed' &&
          item.action.data?.onSuccess?.message
        ) {
          notify(notifySuccess(item.action.data?.onSuccess?.message), {
            hideAfter: 30000,
          })
        } else if (
          item.status === 'failed' &&
          item.action.data?.onError?.message
        ) {
          notify(notifyError(item.action.data?.onError?.message), {
            hideAfter: 30000,
          })
        } else {
          let event_name = item.action.event.name
          let event_status = item.status
          let event_source = item.action.event.app?.tags || [] // this won't be needed after the refactor from above

          if (item.data?.errorMessage?.includes('invalid_grant')) {
            event_name = 'invalid_grant'
          }

          let message = getMessage({ event_name, event_status, event_source })
          if (message) {
            notify(
              event_status === 'completed'
                ? notifySuccess(message)
                : notifyError(message),
              {
                hideAfter: 30000,
              }
            )
          }
        }

        processed_events.current.add(item.id)
      })

    if (event_executions.some(event => event.status === 'completed')) {
      setRefreshAppsLaunchers(Date.now())
    }
  }, [event_executions]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
