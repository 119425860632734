import { useDataValue, useDataChange } from 'Simple/Data.js'

export function getName(person) {
  return `${person.first_name} ${person.last_name}, ${person.gender}, ${person.birth_date}`
}

/** @type {(person: any) => string | null} */
export function maybeGetPhone(person) {
  return (
    person?.contact_method_associations.find(item => item.phone)?.phone
      ?.number || null
  )
}
/** @type {(person: any) => string | null} */
export function maybeGetEmail(person) {
  return (
    person?.contact_method_associations.find(item => item.email)?.email
      ?.address || null
  )
}
/** @type {(person: any) => string | null} */
export function maybeGetZip(person) {
  return (
    person?.contact_method_associations.find(item => item.postal_address)
      ?.postal_address?.zip || null
  )
}

export function useOnClick(props) {
  let value = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'replace',
    viewPath: props.viewPath,
    path: 'selected_patient',
  })

  return function onClick() {
    change({
      id: value.patient._id,
      firstName: value.first_name,
      lastName: value.last_name,
      gender: value.gender,
      birthDate: value.birth_date,
      phone: maybeGetPhone(value),
      email: maybeGetEmail(value),
      zip: maybeGetZip(value),
      // profile_pic_url: value.profile_pic_url,
      // treatment_status: value.patient.tx_cards?.[0]?.txs?.[0].status || null,
    })
  }
}
