// this is an autogenerated file from App/Account/AutomatedEventNotifications/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_AutomatedEventNotifications(
  $updated_at: timestamptz!
  $organization_id: uuid!
) {
  events_action_executions_stream(
    cursor: { initial_value: { updated_at: $updated_at }, ordering: ASC }
    batch_size: 5
    where: {
      status: { _in: [failed, completed] }
      action: { event: { organization_id: { _eq: $organization_id } } }
    }
  ) {
    id
    created_at
    status
    data
    action {
      id
      data
      event {
        id
        name
        app {
          id
          tags: metadata(path: "tags")
        }
      }
    }
  }
}

`