import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let document_template_id = useDataValue({
    context: 'payor',
    path: 'contract.document_template_id',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      document_template_id,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
