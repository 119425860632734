import React from 'react'

import View from './view.js'
import { useIsDisabled } from './helpers.js'

export default function Logic(props) {
  let isDisabled = useIsDisabled(props)

  return (
    <View
      {...props}
      viewPath={props?.dsViewPath || props.viewPath}
      isDisabled={isDisabled}
    />
  )
}
