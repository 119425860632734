import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let coverages = data.tx_category_coverages?.ortho_coverages
    let ortho_coverage = true
    if (!data.tx_category_coverages) {
      ortho_coverage = false
    }

    return {
      id: data.id,
      name: data.name,
      number: data.number,
      carrier: data.insurance_company?.id,
      billing_center: data.insurance_billing_center?.id,
      coverage_id: coverages?.id,
      lifetime_coverage: coverages?.max_lifetime_coverage,
      dependent_age_limit: coverages?.dependent_age_limit,
      reimbursement_rate: coverages?.reimbursement_percentage,
      age_limit_effective: coverages?.age_limit_cutoff,
      annual_deductible: coverages?.deductible_amount,
      waiting_period: coverages?.waiting_period,
      inprogress: coverages?.inprogress,
      downpayment_type: coverages?.downpayment_type,
      downpayment_percentage: coverages?.downpayment_percentage,
      downpayment_percentage_base: coverages?.downpayment_percentage_base,
      downpayment_amount: coverages?.downpayment_amount,
      cob_type: coverages?.cob_type,
      description: data.description,
      ortho_coverage,
      insurance_plan_employers: data.insurance_plan_employers.map(e => ({
        id: e.employer_id,
      })),
    }
  }, [data])
}
