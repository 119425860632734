// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/Contracts/Content/Payor/mutation-create-contract.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_Contracts_Content_Payor(
  $treatment_plan_id: numeric!
  $person_id: numeric!
  $document_template_id: numeric!
) {
  vaxiom_contracts_create(
    treatment_plan_id: $treatment_plan_id
    person_id: $person_id
    document_template_id: $document_template_id
  ) {
    id
  }
}

`