import { useEffect, useState } from 'react'

let initialState = {
  fetching: false,
  data: null,
  error: null,
  headers: null,
  status: null,
  statusText: null,
}

export default function useFetch(url, init = undefined) {
  let [state, setState] = useState(initialState)

  useEffect(() => {
    if (!url) return

    let cancel = false

    function stringifyHeaders(headers) {
      return JSON.stringify(Object.fromEntries(headers.entries()))
    }

    async function execute() {
      try {
        setState(prev => ({ ...initialState, fetching: true }))
        let response = await fetch(url, init)
        if (cancel) return
        let data = await response.text()

        if (response.ok) {
          setState(prev => ({
            ...prev,
            fetching: false,
            data,
            status: response.status,
            headers: stringifyHeaders(response.headers),
            statusText: response.statusText,
          }))
        } else {
          setState(prev => ({
            ...prev,
            fetching: false,
            data: null,
            error: data,
            status: response.status,
            headers: stringifyHeaders(response.headers),
            statusText: response.statusText,
          }))
        }
      } catch (error) {
        if (cancel) return
        setState(prev => ({ ...prev, fetching: false, error }))
      }
    }

    execute()

    return () => {
      cancel = true
    }
  }, [url]) // esint-disable-line ignore init

  return state
}
