// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/InsurancePlan/Content/Insurance/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_InsurancePlan_Content_Insurance($id: numeric!) {
  vaxiom_patient_insurance_plans_by_pk(id: $id) {
    id
    number
    name
    description
    insurance_company {
      id
      carrier_name
    }
    insurance_billing_center {
      id
      name
    }
    insurance_plan_employers {
      employer_id
    }

    tx_category_coverages {
      id
      ortho_coverages {
        id
        max_lifetime_coverage
        dependent_age_limit
        reimbursement_percentage
        age_limit_cutoff
        deductible_amount
        waiting_period
        downpayment_type
        downpayment_amount
        downpayment_percentage
        downpayment_percentage_base
        inprogress
        cob_type
      }
    }
  }
}

`