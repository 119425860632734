import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutationCreateContract from './mutation-create-contract.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataListItemOnSubmit(props, data) {
  let onChangeContractTemplate = useOnChangeContractTemplate(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'change-contract-template':
        return onChangeContractTemplate({ value, originalValue, args, change })

      default:
        return
    }
  }
}

function useOnChangeContractTemplate(props) {
  let treatment_plan_id = useDataValue({
    viewPath: props.viewPath,
    context: 'wizard',
    path: 'selected.treatment_plan_id',
  })
  let person_id = useDataValue({
    context: 'payor',
    path: 'person_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payors',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutationCreateContract] = useMutation(mutationCreateContract)

  return async function onSubmit({ value, args, change }) {
    let mutationResponse = await executeMutationCreateContract({
      treatment_plan_id,
      person_id,
      document_template_id: args.document_template_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem regenerating the contract. Please try again.'
        )
      )
      return
    }

    change(next => {
      next.document_template_id = args.document_template_id
    })

    submit({ type: 'reFetch' })
  }
}
