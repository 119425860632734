import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let res = []

    let parent = data.find(item => item.level === 'parent')
    let parentRecord = asRow(parent)
    res.push(parentRecord)

    let locations = data.filter(item => item.level === 'LOCATION')

    locations.forEach(item => res.push(asRow(item)))

    return res
  }, [data])
}

function asRow(record) {
  return {
    id: record.id,
    _id: record._id,
    name: record.name,
    level: record.level,
    ...Object.fromEntries(
      record.application_properties.map(item => [
        item.message_key.replaceAll('.', '_'),
        item.message_value === 'true',
      ])
    ),
  }
}
