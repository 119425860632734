// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/Contracts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_Contracts($treatment_plan_id: numeric!) {
  treatments_payment_plan_payors(
    where: { treatment_plan_id: { _eq: $treatment_plan_id } }
    order_by: { is_primary: desc }
  ) {
    id
    is_primary
    person_id
    treatment_plan_id
    contract_id
    person {
      id
      first_name
      last_name
    }
    contract {
      id
      document_template_id
      url_signed
    }
  }
}

`