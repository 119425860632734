import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let document_template_id = useDataValue({
    context: 'selected',
    path: 'document_template_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    if (!value || value === document_template_id) return

    submit({
      type: 'change-contract-template',
      document_template_id: value,
    })
  }
}
