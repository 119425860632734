import { useDataSubmit } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value) return
    let booking = value.booking?.[0]
    await submit({
      type: 'setExistingAppointment',
      is_external: true,
      id: value._id,
      type_id: value.type._id,
      treatment_id: value.tx._id,
      patient_id: value.patient._id,
      duration: value.type.appointment_templates?.[0]?.duration ?? null,
      ...(booking
        ? {
            preselect_scheduling_slot_config: {
              start_min: timeToMinutes(booking.local_start_time),
              date: booking.local_start_date,
              chair_id: booking.chair._id,
            },
          }
        : {}),
    })
  }
}
