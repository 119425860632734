import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let sync_id = useDataValue({
    context: 'tab',
    path: 'sync_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutation)

  // TODO: WTF is this? it seems duplicated
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'replace': {
        change(next => {
          let record = next.find(item => item.external.id === args.external_id)
          record.type = 'MATCH'
          record.internal = args.selected_patient
          record.selected = true
        })
        break
      }

      case 'remove': {
        if (!args.external_id) return

        change(next => {
          let record = next.find(item => item.external.id === args.external_id)
          record.type = 'NO_MATCH'
          record.internal = null
          record.selected = false
        })
        break
      }

      case 'complete-sync': {
        // get selected patients and do the sync
        let patients = value
          .filter(item => item.selected)
          .map(item => ({
            external_metadata: item.external.external,
            patient_id: item.internal.id,
          }))

        if (patients.length === 0) {
          return notify(notifyError('Please select patients to sync.'))
        }

        let mutationResponse = await executeMutation({
          sync_id,
          patients,
        })
        if (mutationResponse.error) {
          return notify(
            notifyError('Something went wrong. Please try again later.')
          )
        }

        notify(
          notifySuccess(
            `${mutationResponse.data.apps_complete_sync.patients_synced} patients synced.`
          )
        )
        submit({
          type: 'back',
        })
        break
      }

      default: {
        break
      }
    }
  }
}
