import { useDataValue, DataProvider, useDataChange } from 'Simple/Data.js'
import DetailAction from './DetailAction/view.js'
import React, { useMemo } from 'react'
import { dateISOToDateAndTime } from 'Data/format.js'

import View from './view.js'

export default function Logic(props) {
  let leads = useDataValue({
    context: 'marketing_analytics',
    path: 'leads',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let value = useMemo(() => {
    let columns = [
      {
        header: '',
        accessorKey: 'details',
        cell: cell => {
          return (
            <DataProvider
              context="row"
              value={{ id: cell.row.original.id }}
              viewPath={props.viewPath}
            >
              <DetailAction
                viewPath={`${props.viewPath}/DetailAction(${cell.row.original.id})`}
              />
            </DataProvider>
          )
        },
      },
      {
        header: 'First',
        accessorKey: 'first_name',
      },
      {
        header: 'Last',
        accessorKey: 'last_name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
      },
      {
        header: 'Location',
        accessorKey: 'location',
      },
      {
        header: 'Conversion',
        accessorKey: 'conversion',
        cell: cell => (
          <div style={{ textAlign: 'center' }}>{cell.getValue()}</div>
        ),
      },
      {
        header: 'Paying',
        accessorKey: 'paying',
        cell: cell => (
          <div style={{ textAlign: 'center' }}>{cell.getValue()}</div>
        ),
      },
      {
        header: 'Scheduled',
        accessorKey: 'scheduled',
        cell: cell => (
          <div style={{ textAlign: 'center' }}>{cell.getValue()}</div>
        ),
      },
      {
        header: 'Campaign',
        accessorKey: 'campaign_name',
      },
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Term',
        accessorKey: 'term',
      },
      {
        header: 'Medium',
        accessorKey: 'medium',
      },
      {
        header: 'Contract Value',
        accessorKey: 'fee',
        cell: cell => (
          <div style={{ textAlign: 'center' }}>
            {cell.getValue() === '-' ? '-' : `$${cell.getValue()}`}
          </div>
        ),
      },
      {
        header: 'Returning',
        accessorKey: 'returning',
        cell: cell => (
          <div style={{ textAlign: 'center' }}>{cell.getValue()}</div>
        ),
      },
      {
        header: 'Last Seen',
        accessorKey: 'updated_at',
      },
    ].filter(Boolean)

    if (!leads) return { columns, data: [] }

    return {
      columns,
      data: leads.map(item => {
        console.log('fee', item.fee)
        return {
          ...item,
          first_name: item.patient?.person?.first_name || item.first_name,
          last_name: item.patient?.person?.last_name || item.last_name,
          conversion: yesNo(item.conversion),
          paying: yesNo(item.paying),
          scheduled: yesNo(item.scheduled),
          returning: yesNo(item.newVsReturning === 'returning'),
          location: item.location?.name,
          updated_at: dateISOToDateAndTime(item.updated_at),
        }
      }),
    }
  }, [leads])

  function onSortChange(rows, state) {
    let ids = rows.map(row => row.original.id)
    change(next => {
      next.ordered_rows = ids
    })
  }

  return (
    <View value={value} onSortChange={onSortChange} viewPath={props.viewPath} />
  )
}

function yesNo(value) {
  return value ? 'Y' : 'N'
}
